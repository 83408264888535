const textStack = [
    {
        icon: '/images/video_benefit.png',
        circle: '/images/circle_benefit.png',
        description: 'video',
    },
    {
        icon: '/images/3_users_benefit.png',
        circle: '/images/circle_benefit.png',
        description: 'users',
    },
    {
        icon: '/images/chat_benefit.png',
        circle: '/images/circle_benefit.png',
        description: 'chat',
    },
]

const imagesStack = [
    {
        icon: '/images/jessica_icon.png',
        flag: '/images/jessica_flag.png',
        vector: '/images/vector_profile.png',
        id: 'jessica',
        text: 'Jessica, 23',
    },
    {
        icon: '/images/cometa_icon.png',
        flag: '/images/cometa_flag.png',
        vector: '/images/vector_profile.png',
        id: 'cometa',
        text: 'Cometa, 23',
    },
    {
        icon: '/images/samanta_icon.png',
        flag: '/images/samanta_flag.png',
        vector: '/images/vector_profile.png',
        id: 'samanta',
        text: 'Samanta, 25',
    },
    {
        icon: '/images/molly_icon.png',
        flag: '/images/molly_flag.png',
        vector: '/images/vector_profile.png',
        id: 'molly',
        text: 'Molly, 25',
    },
]

export { textStack, imagesStack }
