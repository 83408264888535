import React, { useCallback, useState, useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import { useLocale } from "react-easy-localization";
import { v4 as uuidv4 } from 'uuid';
import { SectionOnline, Header, Main, Footer, ServiceTerms, BillingTerms, Policy } from './containers'
import exportedObj from './helpers/generatedStringLink';
import Wrapper from './layout/Wrapper'
import { getDomainData } from './helpers/getDomainData';
import './App.scss'

function App() {
    const { i18n, changeLanguage } = useLocale();
    const [content] = useState(i18n)
    const [color, setColor] = useState(null)
    const [urlAppLink, setUrlAppLink] = useState('')
    const [userFbId] = useState(uuidv4())

    const changeColorState = useCallback(() => {
        setColor(false)
    }, [])

    const changeColorStateSecondary = useCallback(() => {
        setColor(true)
    }, [])

    const handleBtnClick = () => {
        const timeOut = setTimeout(() => {
            window.open(`${urlAppLink}`, '_blank')
            clearTimeout(timeOut)
        }, 500)
        window.fbq('init', 'it7gogoct3t7pyn0gmgc8k16rws5vr', {
            'extern_id': userFbId,
            'external_id': userFbId
        });
    }

    const changeAppLanguage = async () => {
        switch (window.navigator.language.slice(0, 2)) {
            case 'en':
                changeLanguage('en')
                break;
            case 'ar':
                changeLanguage('ar')
                break;
            case 'fr':
                changeLanguage('fr')
                break;
            case 'es':
                changeLanguage('es')
                break;
            case 'de':
                changeLanguage('de')
                break;
            case 'he':
                changeLanguage('il')
                break;
            default:
                changeLanguage('en')
                break;
        }
    }

    useEffect(() => {
        let url = exportedObj.urlString

        changeAppLanguage()
        getDomainData()
            .then(async ({ loc: country, ip }) => {
                const { hostname, href } = window.location
                const parsedLink = href.slice(href.indexOf('?') + 1)
                const requestBody = JSON.stringify({
                    referrer: parsedLink,
                    domain: hostname,
                    ip: ip,
                    country: country
                })
                await fetch('https://prlivechat.com:3000/landingVisit', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: requestBody
                })
                url = `${url}&external_id=${userFbId}&userIp=${ip}&browser=${encodeURI(navigator.userAgent)}`
                setUrlAppLink(url)
            })
            .catch((err) => {
                console.log(err)
            })
    })

    return (
        <div className="App">
                <Wrapper>
                    <Router>
                        <Header handleClick={handleBtnClick} color={color} content={content}/>
                        <Switch>
                            <Route path="/" exact>
                                <Main handleClick={handleBtnClick} changeColor={changeColorState} content={content}/>
                                <SectionOnline handleClick={handleBtnClick} content={content}/>
                            </Route>
                            <Route path="/policy">
                                <Policy changeColor={changeColorStateSecondary} />
                            </Route>
                            <Route path="/terms">
                                <BillingTerms changeColor={changeColorStateSecondary} />
                            </Route>
                            <Route path="/service">
                                <ServiceTerms changeColor={changeColorStateSecondary} />
                            </Route>
                            <Redirect to="/" />
                        </Switch>
                        <Footer content={content}/>
                    </Router>
                </Wrapper>
        </div>
    )
}

export default App
