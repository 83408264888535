import React, { Fragment } from 'react'
import Button from '../../UI/Button'
import './MainBannerText.scss'

const MainBannerText = ({ handleClick, content }) => {
    const { main_text, main_text_secondary, btn_main  } = content
    return (
        <Fragment>
            <div className="text-group">
                <h1>{main_text}</h1>
                <div className="text-group__secondary"><span>{main_text_secondary}</span></div>
                <div className="text-group__btn">
                    <Button
                        text={btn_main}
                        classes={['btn', 'btn-yellow']}
                        handleClick={handleClick}
                    />
                </div>
            </div>
        </Fragment>
    )
}


export default MainBannerText
