import React from 'react'
import ReactDOM from 'react-dom'
import { LocaleProvider } from "react-easy-localization";
import { gtmId } from './config-gtm'
import GoogleTagManager from './GoogleTagManager'
import App from './App'
import './index.scss'

const resources = {
    en: {
        main_text: 'Join Live Video Chat',
        main_text_secondary: 'to speak with strangers from 85 countries',
        benefits: {
            benefit1_title: 'Live Video Chat',
            benefit1_description: 'Chat with strangers and have fun',
            benefit2_title: 'No fake profiles',
            benefit2_description: 'Only real profiles from all over the world',
            benefit3_title: '24/7 video chatting',
            benefit3_description: 'No swiping around or waiting days for a reply',
        },
        online_text: 'people online',
        btn_online: 'Start chat for free',
        btn_main: 'Try for free',
        btn_header: 'Install Now',
        header_text: 'Video Chat', 
        privacy: 'Privacy Policy',
        billing: 'Billing Terms',
        terms: 'Terms of Service',
        rules: 'By continuing you agree and accept the following rules',
        rights: 'All Rights Reserved'
    },
    ar: {
        main_text: " نحن في انتظارك",
        main_text_secondary: 'يمكنك التحدث مع مستخدمي أوليف من جميع أنحاء العالم',
        benefits: {
            benefit1_title: 'دردشة فيديو حية',
            benefit1_description: 'يمكنك التحدث مع مستخدمي أوليف من جميع أنحاء العالم',
            benefit2_title: 'لا وجود لحسابات مزيفة',
            benefit2_description: ' قوموا بتحميله الآن للتحدث مع أشخاص حقيقيين',
            benefit3_title: 'تحدث إلى أناس حقيقيين',
            benefit3_description: 'لا داعي للتمرير يمينا وشمالا أو الانتظار لأيام للحصول على رد',
        },
        online_text: ' شخصًا عبر الإنترنت',
        btn_online: 'حمّل التطبيق مجانا',
        btn_main: 'حمّل التطبيق مجانا',
        btn_header: 'حمل الآن',
        header_text: 'دردشة فيديو حية',
        privacy: 'سياسة الخصوصية',
        billing: 'شروط الفوترة',
        terms: 'شروط الخدمة',
        rules: 'من خلال المواصلة، فإنك توافق وتقبل القواعد التالية',
        rights: 'جميع الحقوق محفوظة'
    },
    fr: {
        main_text: 'Rejoignes le chat vidéo en direct',
        main_text_secondary: 'pour bavarder avec de nouvelles personnes de 85 pays',
        benefits: {
            benefit1_title: 'Chat vidéo en direct',
            benefit1_description: 'Bavarde avec de nouvelles personnes et amuse-toi',
            benefit2_title: 'Pas de faux profils',
            benefit2_description: 'Seulement des profils originaux du monde entier',
            benefit3_title: '24/7 Chat vidéo',
            benefit3_description: 'Pas de besoin de tourner en rond ou d\'attendre une réponse pendant des jours',
        },
        online_text: 'gens en-ligne',
        btn_online: 'Démarre le chat gratuitement',
        btn_main: 'Essaie gratuitement',
        btn_header: 'Installe maintenant',
        header_text: 'Chat Vidéo',
        privacy: 'Politique de confidentialité',
        billing: 'Conditions de Facturation',
        terms: 'Conditions d\'utilisation',
        rules: 'En continuant, tu es d\'accord et accepter les règles suivantes',
        rights: 'Tous les droits réservés'
    },
    es: {
        main_text: 'Únete a un videochat en directo',
        main_text_secondary: 'para hablar con otras personas de 85 países.',
        benefits: {
            benefit1_title: 'Videochat en Directo',
            benefit1_description: 'Chatea con otras personas y diviértete',
            benefit2_title: 'Sin perfiles falsos',
            benefit2_description: 'Solo perfiles reales de todo el mundo',
            benefit3_title: 'Videochat 24/7',
            benefit3_description: 'No tienes que deslizar para los lados o esperar días para recibir una respuesta.',
        },
        online_text: 'personas en línea',
        btn_online: 'Iniciar chat gratis',
        btn_main: 'Probar gratis',
        btn_header: 'Instalar ahora',
        header_text: 'Videochat',
        privacy: 'Política de privacidad',
        billing: 'Términos de facturación',
        terms: 'Términos de servicio',
        rules: 'Si continúas, estás de acuerdo y acepta las siguientes reglas',
        rights: 'Todos los derechos reservados'
    },
    de: {
        main_text: 'Tritt dem Live-Video-Chat bei,\n ',
        main_text_secondary: 'um mit neuen Leuten aus 85 Ländern zu sprechen',
        benefits: {
            benefit1_title: 'Live-Videochat',
            benefit1_description: 'Chatte mit neuen Leuten and unterhalte dich',
            benefit2_title: 'Keine Fake-Profile',
            benefit2_description: 'Nur echte Profile aus der ganzen Welt',
            benefit3_title: '24/7 video-chat',
            benefit3_description: 'Keine Sorge und keine Warten auf Antwort ist erforderlich',
        },
        online_text: 'Personen online',
        btn_online: 'Fange Chat kostnelos',
        btn_main: 'Versuche kostenlos',
        btn_header: 'Installiere gleich!',
        header_text: 'Video-chat',
        privacy: 'Datenschutz Erklärung',
        billing: 'Abrechnungsbedingungen',
        terms: 'AGB',
        rules: 'Indem du fortfährst, bist du mit den folgenden Regeln einverstanden  und akzeptierst du sie',
        rights: 'Alle Rechte vorbehalten'
    },
    il: {
        main_text: "הצטרף לוידאו צ'אט",
        main_text_secondary: 'כדי לדבר עם זרים מ85 מדינות',
        benefits: {
            benefit1_title: "וידיאו 'אט בשידור חי",
            benefit1_description: 'דבר עם זרים ותהנה',
            benefit2_title: "ללא פרופילים מזוייפים",
            benefit2_description: "רק פרופילים אמיתיים מכל העולם",
            benefit3_title: "ווידאו צ'אט 24/7",
            benefit3_description: "בלי להחלי' ימינה או שמאלה כל היום או לחכות ימים בשביל תגובה"
        },
        online_text: 'אנשים אונליין',
        btn_online: "התחל צ'אט בחינם",
        btn_main: 'נסה בחינם',
        btn_header: 'התקן עכשיו',
        header_text: "ווידאו צ'אט",
        privacy: 'מדיניות פרטיות',
        billing: 'תנאי חיוב',
        terms: 'תנאי שימוש',
        rules: 'כשאת/ה ממשיכ/ה את/ה מסכימ/ה לחוקים הבאים',
        rights: 'כל הזכויות שמורות'
    }
};

ReactDOM.render(
    <React.StrictMode>
        <GoogleTagManager gtmId={gtmId} />
        
        <LocaleProvider resources={resources}>
            <App />
        </LocaleProvider>
    </React.StrictMode>,
    document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
