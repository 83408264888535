import React, { useState, useEffect } from 'react'
import Button from '../../components/UI/Button'
import './SectionOnline.scss'

const logo = '/images/logo_olive.png'

const SectionOnline = ({ handleClick, content }) => {
    const [online, setOnline] = useState(8316)

    useEffect(() => {
        let timerInterval
        const randomInteger = (min, max) => {
            timerInterval = setInterval(() => {
                const value = Math.floor(min + Math.random() * (max + 1 - min))
                setOnline(value)
            }, 3000)
        }
        randomInteger(8300, 8400)
        return () => {
            clearInterval(timerInterval)
        }
    }, [])

    return (
        <div className="SectionOnline">
            <div className="SectionOnline__logo">
                <img src={logo} alt={`footer_logo`}></img>
            </div>
            <div className="SectionOnline__text">
                <h1 className="SectionOnline__text__header">{online}</h1>
                <div className="SectionOnline__text__secondary">
                    <span>{content.online_text}</span>
                    <img
                        src={'/images/online_footer.png'}
                        alt={'online_icon'}
                        className="SectionOnline__text__secondary__online-icon"
                    />
                </div>
            </div>
            <div className="SectionOnline__btn">
                <Button text={'Start Chat for Free'} classes={['btn', 'btn-yellow']} handleClick={handleClick} />
            </div>
        </div>
    )
}

export default SectionOnline
