import React, { useEffect } from 'react'

export default function ServiceTerms({ changeColor }) {
    useEffect(() => {
        changeColor()
        window.scroll(0, 0)
    }, [])
    return (
        <div className="container">
            <h1>Terms of Service</h1>
            <h3>Welcome to Olive!</h3>
            <div>
                We've drafted these Terms of Service (which we simply call the "Terms") so that you'll know the rules
                that govern our relationship with you. Although we have tried our best to strip the legalese from the
                Terms, there are places where these Terms may still read like a traditional contract. There's a good
                reason for that: These Terms do indeed form a legally binding contract between you and Olive. So please
                read them carefully.
            </div>
            <div>
                By using the Services, you agree to the Terms. Of course, if you don't agree with them, then don't use
                the Services.
            </div>
            <div>
                The following Terms of Service outline your obligations when using our mobile application (“App”) or
                Service (as defined herein), or any of the information, text, graphics, videos, or other files,
                materials, data or content of any kind whatsoever created or provided by or through the App or the
                Service or through your ability to sell products on the App and generate User Contributed Content (as
                defined herein). Please also review our Privacy Policy, which is a part of these Terms of Service and
                which outlines our practices towards handling any personal information that you may provide to us.
            </div>
            <div>
                The App and the Service are owned and operated by (“Olive,” “we,” or “us”) and are accessed by you under
                the Terms of Service described herein (“Terms of Service” or “Agreement”). Please read these Terms of
                Service carefully before using the App or the Service. By accessing the App or using any part of the
                Service, you agree to become bound bythese terms and conditions. If you do not agree to all these terms
                and conditions, then you may not access the App or use the Service. Nothing in this Agreement shall be
                deemed to confer any third party rights or benefits.
            </div>
            <div>
                ARBITRATION NOTICE: WE WANT TO LET YOU KNOW UPFRONT THAT THESE TERMS CONTAIN AN ARBITRATION CLAUSE A
                LITTLE LATER ON. EXCEPT FOR CERTAIN TYPES OF DISPUTES MENTIONED IN THAT ARBITRATION CLAUSE, YOU AND
                oLIVE AGREE THAT DISPUTES BETWEEN US WILL BE RESOLVED BY MANDATORY BINDING ARBITRATION, AND YOU AND
                oLIVE WAIVE ANY RIGHT TO PARTICIPATE IN A CLASS-ACTION LAWSUIT OR CLASS-WIDE ARBITRATION.
            </div>
            <h3>1. Who Can Use the Services</h3>
            <div>
                No one under 18 is allowed to create an account or use the Services. We may offer additional Services
                with additional terms that may require you to be even older to use them. So please read all terms
                carefully.
            </div>
            <div>By using the Services, you state that:</div>
            <ul>
                <li>You can form a binding contract with Olive;</li>
                <li>
                    You will comply with these Terms and all applicable local, state, national, and international laws,
                    rules, and regulations.
                </li>
            </ul>
            <div>
                If you are using the Services on behalf of a business or some other entity, you state that you are
                authorized to grant all licenses set forth in these Terms and to agree to these Terms on behalf of the
                business or entity. If you are using the Services on behalf of an entity of the United States
                Government, you agree to the Amendment to Olive Terms of Service for the United States Government.
            </div>
            <div>
                You hereby warrant that you are at least 18 years old. In the event that the information you provide in
                this regard is not truthful, Olive shall not be liable as it cannot verify the age of its users. If you
                are under 18years old, do not attempt to register or use the App or the Service. Ifyou are under the age
                of 18, you may use the Service, with or without registering, only with the approval of your parent or
                guardian. In addition, you should review these terms with your parent or guardian to make sure that you
                and your parent or guardian understand and agree with these terms.
            </div>
            <div>
                The license to use the Software and Services is not valid in any jurisdiction where prohibited. The
                Software and Services are intended solely for users who are eighteen (18) years of age or older, and any
                registration, use or access to the Software or Services by anyone under 18 is unauthorized, unlicensed,
                and in violation of this Terms of Use. Olive may terminate your account, delete any content or
                information that you have posted on the Services, and/or prohibit you from using or accessing the
                Software or Services (or any portion, aspect or feature of the Services) for any reason or for no
                reason, at any time in its sole discretion, with or without notice, including without limitation if it
                believes that you are under 18.
            </div>
            <h3>2. Rights We Grant You</h3>
            <div>
                Olive grants you a personal, worldwide, royalty-free, non-assignable, nonexclusive, revocable, and
                non-sublicensable license to access and use the Services. This license is for thesole purpose of letting
                you use and enjoy the Service's benefits in a way that these Terms and our usage policies.
            </div>
            <div>
                Any software that we provide you may automatically download and install upgrades, updates,or other new
                features. You may be able to adjust these automatic downloads through your device's settings.
            </div>
            <div>
                You may not copy, modify, distribute, sell, or lease any part of our Services, nor may you reverse
                engineer or attempt to extract the source code of that software, unless applicable laws prohibit these
                restrictions or you have our written permission to do so.
            </div>
            <h3>3. Rights You Grant Us</h3>
            <div>
                Many of our Services let you create, upload, post, send, receive, and store content. When you do that,
                you retain whatever ownership rights in that content you had to begin with. But you grant us a license
                to use that content. How broad that license is depends on which Services you use and the Settings you
                have selected.
            </div>
            <div>
                For all Services, you grant Olive a worldwide, royalty-free, sublicensable, and transferable license to
                host, store, use, display, reproduce, modify, adapt, edit, publish, and distribute that content. This
                license is for the limited purpose of operating, developing, providing, promoting, and improving the
                Services and researching and developing new ones.
            </div>
            <div>
                Because Local is inherently public and chronicle matters of public interest, the license you grant us
                for content submitted to those Services is broader. In addition to the rights you grant us in connection
                with other Services, you also grant us a perpetual license to create derivative works from, promote,
                exhibit, broadcast, syndicate, publicly perform, and publicly display content submitted to Local or any
                other crowd-sourced Services in any form and in any and all media or distribution methods (now known or
                later developed). To the extent it's necessary,you also grant Olive and our business partners the
                unrestricted, worldwide, perpetual right and license to use your name, likeness, Local content that you
                upload or send. This means, among other things, that you will not be entitled to any compensation from
                Olive or our business partners if your name, likeness or Local.
            </div>
            <div>
                While we're not required to do so, we may access, review, screen, and delete your content at any time
                and for any reason, including if we think your content violates these Terms. You alone though remain
                responsible for the content you create, upload, post, send, or store through the Service.
            </div>
            <div>
                The Services may contain advertisements. In consideration for Olive letting you access and use the
                Services, you agree that Olive, its affiliates, and third-party partners may place advertising on the
                Services.
            </div>
            <div>
                We always love to hear from our users. But if you volunteer feedback or suggestions, just know that we
                can use your ideas without compensating you.
            </div>
            <h3>4. Content</h3>
            <div>
                Content transmitted by the use of the Software and Services is entirely the responsibility of the person
                from whom such Content originated. You use the Software and Services at your own risk and understand
                that by using the Software or any of the Services, you may be exposed to Content that is offensive,
                harmful to minors, indecent or otherwise objectionable. Olive does not endorse any Content and expressly
                disclaims any and all liability in connection with any and all Content transmitted or displayed using
                the Software and/or Services. You hereby release and discharge the Olive Parties (as defined below) from
                any and all claims and demands arising out of or relating to any Content.
            </div>
            <h3>4.1. Your Content</h3>
            <div>
                You acknowledge and agree that you are solely responsible for any Content that you transmit or display
                through the Software or Services and that Olive (and Apple or Google if you downloaded the Software from
                the App Store or Google Play) is not responsible to you or any third party in connection with any
                Content. You further agree to pay for all royalties, fees, and any other monies owing any person by
                reason of any of your Content. In addition, you represent and warrant that: (a) you are the creator and
                owner of or have the necessary rights to transmit or display the Content; and (b) the Content you
                transmit or you display does not and will not: (i) infringe, violate, or misappropriate any third-party
                right, including any copyright, trademark, patent, trade secret, moral right, privacy right, right of
                publicity, or any other intellectual property or proprietary right, or (ii) slander, defame, libel, or
                invade the right of privacy, publicity or other property rights of any other person. VIOLATORS OF THESE
                THIRD-PARTY RIGHTS MAY BE SUBJECT TO CRIMINAL AND CIVIL LIABILITY. Olive RESERVES ALL RIGHTS AND
                REMEDIES AGAINST ANYONE WHO VIOLATES ANY OF THE PROVISIONS OF THIS TERMS OF USE.
            </div>
            <h3>5. Privacy</h3>
            <div>
                Your privacy matters to us. You can learn how we handle your information when you use our Services by
                reading our privacy policy. We encourage you to give the Privacy Policy a careful look because, by using
                our Services, you agree that Olive can collect, use, and transfer your information consistent with that
                policy.
            </div>
            <h3>6. Respecting Other People's Rights</h3>
            <div>
                Olive respects the rights of others. And so should you. You therefore may not upload, send, orstore
                content that:
            </div>
            <ul>
                <li>
                    violates or infringes someone else's rights of publicity, privacy, copyright, trademark, or other
                    intellectual-property right;
                </li>
                <li>bullies, harasses, or intimidates;</li>
                <li>defames; or spams or solicits Olive's users;</li>
                <li>
                    is inappropriate to other users or illegal, including, but not limited to, anything that is
                    defamatory, inaccurate, unlawful, harmful, threatening, abusive, harassing, vulgar, offensive,
                    obscene, pornographic, hateful, or promotes violence, discrimination, bigotry, racism, or hatred, as
                    determined by Olive in its sole discretion;
                </li>
                <li>
                    introduces viruses, time-bombs, worms, cancelbots, Trojan horses and/or other harmful code, - is
                    reverse look-up or trace any information of any other User or visitor or otherwise use the Services
                    for the purpose of obtaining information of any User or visitor;
                </li>
                <li>
                    attempts to gain unauthorized access to any portion of the Services, or any systems or networks by
                    hacking, password "mining" or any other illegitimate means;
                </li>
                <li>
                    uses any "deep-link", "page-scrape", "robot", "spider" or other automatic device, program, algorithm
                    or methodology, or any manual process to access, acquire, copy or monitor any portion of the
                    Services or any data or materials contained therein or obtain or attempt to obtain any materials,
                    documents or information through any means not purposely made available through the System;
                </li>
                <li>
                    asks or offers sexually explicit images or engage in any activity harmful to minors or otherwise
                    violates these terms.
                </li>
            </ul>
            <div>You must also respect Olive's rights. These Terms do not grant you any right to:</div>
            <ul>
                <li>use branding, logos, designs, photographs, videos, or any other materials used in our Services;</li>
                <li>
                    copy, archive, download, upload, distribute, syndicate, broadcast, perform, display, make available,
                    or otherwise use any portion of the Services or the content on the Services except asset forth in
                    these Terms;
                </li>
                <li>defames; or spams or solicits Olive's users;</li>
            </ul>
            <div>
                In short: You may not use the Services or the content on the Services in ways that are not authorized by
                these Terms. Nor may you help or enable anyone else in doing so.
            </div>
            <div>
                We reserve the right at all times (but will not have an obligation) to remove or refuse to distribute
                any content contributed by Olive users, such as content which violates these Terms of Service. We also
                reserve the right to access, read, preserve, and disclose any information aswe reasonably believe is
                necessary to:
            </div>
            <ul>
                <li>satisfy any applicable law, regulation, legal process or governmental request;</li>
                <li>enforce these Terms of Service, including investigation of potential violations here of;</li>
                <li>detect, prevent, or otherwise address fraud, security or technical issues;</li>
                <li>
                    respond to member support requests, or protect our rights, property or safety, and that of our users
                    and the public.
                </li>
            </ul>
            <div>
                We will not be responsible or liable for the exercise or non-exercise of this right under these Terms of
                Service.
            </div>
            <div>
                You understand that by using or accessing the App or the Service you may be exposed to content that
                might be offensive, harmful, inaccurate or otherwise inappropriate material, or in some cases, postings
                that have been mislabeled or are otherwise deceptive. Under no circumstances will we be liable in any
                way for any content or communications, including, but not limited to, any errors or omissions in any
                form of User Contributed Content, or any loss ordamage of any kind incurred as a result of the use of
                any Olive content or content posted by users which are posted, emailed, transmitted or otherwise made
                available on the App or through the Service.
            </div>
            <h3>7. Respecting Copyright</h3>
            <div>
                You agree to only upload, post, submit or otherwise transmit User Contributed Content: (i) that you have
                the lawful right to use, copy, distribute, transmit, or display; and (ii) that does not infringe the
                intellectual property rights or violate the privacy rights of any third party (including, without
                limitation, copyright, trademark, patent, trade secret, or other intellectual property right, or moral
                right or right of publicity). Olive respects the legal rights of others, and asks that its users do the
                same.
            </div>
            <div>
                Olive honors the requirements set forth in the Digital Millennium Copyright Act. We therefore take
                reasonable steps to expeditiously remove from our Services any infringing material that we become aware
                of. And if Olive becomes aware that one of its users has repeatedly infringed copyrights, we will take
                reasonable steps within our power to terminate the user's account.
            </div>
            <div>
                We make it easy for you to report suspected copyright infringement. If you believe that anything on the
                Services infringes a copyright that you own or control, please notice with us:{' '}
                <a href="mailto:support@oliveapp.net">support@oliveapp.net</a>.
            </div>
            <div>If you file a notice with our Copyright Agent, must:</div>
            <ul>
                <li>
                    contain the physical or electronic signature of a person authorized to act on behalf of the
                    copyright owner;
                </li>
                <li>identify the copyrighted work claimed to have been infringed;</li>
                <li>
                    identify the material that is claimed to be infringing or to be the subject of infringing
                    activityand that is to be removed, or access to which is to be disabled, and information reasonably
                    sufficient to let us locate the material;
                </li>
                <li>
                    provide your contact information, including your address, telephone number, and an email address;
                </li>
                <li>
                    provide a personal statement that you have a good-faith belief that the use of the material in the
                    manner complained of is not authorized by the copyright owner, its agent, or the law;
                </li>
                <li>
                    provide a statement that the information in the notification is accurate and, under penalty of
                    perjury, that you are authorized to act on behalf of the copyright owner.
                </li>
            </ul>
            <h3>8. Safety</h3>
            <div>
                We try hard to keep our Services a safe place for all users. But we can't guarantee it. That's where you
                come in. By using the Services, you agree that:
            </div>
            <ul>
                <li>You will not use the Services for any purpose that is illegal or prohibited in these Terms.</li>
                <li>
                    You will not use any robot, spider, crawler, scraper, or other automated means or interface toaccess
                    the Services or extract other user's information.
                </li>
                <li>
                    You will not use or develop any third-party applications that interact with the Services or other
                    users' content or information without our written consent.
                </li>
                <li>
                    You will not use the Services in a way that could interfere with, disrupt, negatively affect,
                    orinhibit other users from fully enjoying the Services, or that could damage, disable, overburden,
                    or impair the functioning of the Services.
                </li>
                <li>
                    You will not use or attempt to use another user's account, username, or password without their
                    permission.
                </li>
                <li>You will not solicit login credentials from another user.</li>
                <li>
                    You will not post content that contains pornography, graphic violence, threats, hate speech, guns or
                    other weaponry, or incitements to violence.
                </li>
                <li>
                    You will not upload viruses or other malicious code or otherwise compromise the security ofthe
                    Services.
                </li>
                <li>
                    You will not attempt to circumvent any content-filtering techniques we employ, or attempt toaccess
                    areas or features of the Services that you are not authorized to access.
                </li>
                <li>You will not probe, scan, or test the vulnerability of our Services or any system or network.</li>
                <li>You will not encourage or promote any activity that violates these Terms.</li>
            </ul>
            <div>
                We have the right to investigate and prosecute violations of any of the above, including intellectual
                property rights infringement and App security issues, to the fullest extent of the law. We may involve
                and cooperate with law enforcement authorities in prosecuting Olive users who violate these Terms of
                Service. You acknowledge that we have the right to monitor your access to or use of the App and/or the
                Service for operating purposes, to ensure your compliance with these Terms of Service, or to comply with
                applicable law or the order or requirement of a court, administrative agency or other governmental body.
            </div>
            <div>
                We also care about your safety while using our Services. So do not use our Services in a way that would
                distract you from obeying traffic or safety laws. And never put yourself or others in harm's way.
            </div>
            <h3>9. Your Account</h3>
            <div>
                You are responsible for any activity that occurs in your account. So it's important that you keep your
                account secure.
            </div>
            <div>By using the Services, you agree that, in addition to exercising common sense:</div>
            <ul>
                <li>You will not create more than one account for yourself.</li>
                <li>
                    You will not create another account if we have already disabled your account, unless you have our
                    written permission to do so.
                </li>
                <li>
                    You will not buy, sell, rent, or lease access to your Olive account without our written permission.
                </li>
                <li>You will not share your authorization data.</li>
                <li>
                    You will not log in or attempt to access the Services through unauthorized third-party applications
                    or clients.
                </li>
            </ul>
            <div>
                If you think that someone has gained access to your account, please immediately reach out to Olive
                Support at <a href="mailto:support@oliveapp.net">support@oliveapp.net.</a>
            </div>
            <h4>Important FAQ for your Account:</h4>
            <h4>1. Why my account is blocked?</h4>
            <div>
                All the blocked users had certain pornographic behaviors. Any pornographic, naked, sexual and offensive
                behaviors or contents are NOT ALLOWED, including pornographic words and contents in the username or in
                profile pictures. Inappropriate dressing or words are not allowed either.
            </div>
            <h4>2. How do I report when I encounter the inappropriate or pornographic behaviors?</h4>
            <div>You can report him/her during video chat or in your match history.</div>
            <h3>10. Purchases and Payments</h3>
            <div>
                We may offer various friends making Services that you can purchase and use through the Services. You
                don't own these Services; instead you buy enough app coins to use them. You'll always be shown the price
                for Services before you complete a purchase. But Olive does not handle payments or payment processing
                for any purchases; those are handled by third-party payment providers or service providers (such as,
                Apple's iOS Store and Google's Google Play Store, among others). Some third-party service providers may
                charge you sales tax, dependingon where you live. Please check the third-party service provider's
                relevant terms for details.
            </div>
            <div>
                Subject to any applicable additional terms and conditions, all purchases are final and non-refundable.
                And because our performance begins once you tap buy and we give you immediate access to your purchase,
                you waive any right you may have under EU or other local law to cancel your purchase once it's completed
                or to get a refund. BY ACCEPTING THESE TERMS, YOU AGREE THAT oLIVE IS NOT REQUIRED TO PROVIDE A REFUND
                FOR ANY REASON.
            </div>
            <div>
                Some of the Virtual Products we offer are for one-time use only, while others are for repeated use. But
                please note that "repeated" does not mean "forever." We may change, modify, or eliminate Virtual
                Products at any time, with or without notice. You agree that we will bear no liability to you or any
                third party if we do so. If we suspend or terminate your account, you will lose any Products you
                purchased through the Services.
            </div>
            <div>
                It's your sole responsibility to manage your purchases. If you are under 18 (or younger than the age of
                legal majority in your place of residence), you must obtain your parent's or guardian's consent before
                making any purchases.
            </div>
            <div>
                Olive does not transmit any funds and is not a money-services business. To the extent such functionality
                is made available in the Services, it is provided by an unaffiliated third party, and like any other
                third-party service, subject to that third party's separate Terms of Service.
            </div>
            <div>
                Olive offers in-app purchases ("IAP") through iTunes, Google Play or other application platforms
                authorized by Olive. If you choose to make an IAP, you will be prompted to enter details for your
                account with your application platform, and your application platform account(e.g. iTunes Account) will
                be charged for the IAP in accordance with the terms disclosed to you at the time of purchase as well as
                the general terms for IAP that apply to your application platform account. Some application platforms
                may charge you sales tax, depending on where you live. If you purchase an auto-recurring periodic
                subscription IAP, your application platform account will continue to be billed for the subscription
                until you cancel the IAP. After your initial subscription commitment period, and again after any
                subsequent subscription period, your subscription will automatically continue for an additional
                equivalent period, at the price you agreed to when subscribing. If you do not wish your subscription to
                renew automatically, or if you want to change or terminate your subscription, you can do so within your
                application platform account and follow instructions to cancel your subscription, even if you have
                otherwise deleted your account with us or if you have deleted the Olive application from your device. If
                you cancel your subscription, you may use your subscription until the end of your then-current
                subscription term, and your subscription will not be renewed after your then-current term expires.
                Deleting your account on Olive or deleting the Olive application from your device does not cancel your
                subscription; Olive will retain all funds charged to your application platform until you cancel your
                subscription.
            </div>
            <div>
                Generally, all charges for IAP are nonrefundable, and there are no refunds or credits for partially used
                periods. We may make an exception if a refund for a subscription offering is requested within fourteen
                days of the transaction date, or if the laws applicable in your jurisdiction provide for refunds. For
                subscribers residing in the EU or European Economic Area, in accordance with local law, you are entitled
                to a full refund during the 14 days after the subscription begins. Please note that this 14-day period
                commences when the subscription starts.
            </div>
            <div>
                To request a refund: If you subscribed using your Apple ID, refunds are handled by Apple, notOlive. To
                request a refund, go to iTunes, click on your Apple ID, select “Purchase history,” find the transaction
                and hit "Report Problem". You can also submit a request at{' '}
                <a href="https://getsupport.apple.com" target="_blank" rel="noreferrer">
                    https://getsupport.apple.com
                </a>
                .
            </div>
            <div>
                If you subscribed using your Google Play Store account: please contact Google Play customersupport with
                your order number for the Google Play Store (you can find the order number in the order confirmation
                email or by logging in to Google Wallet).
            </div>
            <h3>11. Data Charges and Mobile Phones</h3>
            <div>
                You are responsible for any mobile charges that you may incur for using our Services, including
                text-messaging and data charges. If you're unsure what those charges may be, you should ask your service
                provider before using the Services.
            </div>
            <div>
                Olive may use carrier distributed mobile messaging (SMS) to verify ownership of a registeredmobile phone
                number.
            </div>
            <div>12. Third-Party Services</div>
            <div>
                If you use a service, feature, or functionality that is operated by a third party and made available
                through our Services (including Services we jointly offer with the third party), each party's terms will
                govern the respective party's relationship with you. Olive is not responsible or liable for those third
                party's terms or actions taken under the third party's terms.
            </div>
            <h3>13. Modifying the Services and Termination</h3>
            <div>
                We're relentlessly improving our Services and creating new ones all the time. That means we may add or
                remove features, products, or functionalities, and we may also suspend or stop theServices altogether.
                We may take any of these actions at any time, and when we do, we may not provide you with any notice
                beforehand.
            </div>
            <div>
                Olive may also terminate these Terms with you at any time, for any reason, and without advanced notice.
                That means that we may stop providing you with any Services, or impose new or additional limits on your
                ability to use the Services. For example, we may deactivate your account due to prolonged inactivity or
                inappropriate behavior, and we may reclaim your username at any time for any reason.
            </div>
            <div>Both you and Olive continue to be bound by Sections 3, 6, 10, 13-20 of the Terms.</div>
            <h3>14. Indemnity</h3>
            <div>
                You agree, to the extent permitted under applicable law, to indemnify, defend, and hold harmless Olive,
                our directors, officers, employees, and affiliates from and against any and all complaints, charges,
                claims, damages, losses, costs, liabilities, and expenses (including attorneys' fees) due to, arising
                out of, or relating in any way to:
            </div>
            <ul>
                <li>your access to or use of the Services;</li>
                <li>your content;</li>
                <li>your breach of these Terms.</li>
            </ul>
            <h3>15. Disclaimers</h3>
            <div>
                We try to keep the Services up and running and free of annoyances. But we make no promises that we will
                succeed.
            </div>
            <div>
                THE SERVICES ARE PROVIDED "AS IS" AND "AS AVAILABLE" AND TO THE EXTENT PERMITTED BY APPLICABLE LAW
                WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED
                WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT. IN
                ADDITION, WHILE oLIVE ATTEMPTS TO PROVIDE A GOOD USER EXPERIENCE, WE DO NOT REPRESENT OR WARRANT THAT:
                (A) THE SERVICES WILL ALWAYS BE SECURE, ERROR-FREE,OR TIMELY; (B) THE SERVICES WILL ALWAYS FUNCTION
                WITHOUT DELAYS, DISRUPTIONS, OR IMPERFECTIONS; OR (C) THAT ANY oLIVE CONTENT, USER CONTENT, OR
                INFORMATION YOU OBTAIN ON OR THROUGH THE SERVICES WILL BE TIMELY OR ACCURATE.
            </div>
            <div>
                oLIVE TAKES NO RESPONSIBILITY AND ASSUMES NO LIABILITY FOR ANY CONTENT THAT YOU, ANOTHER USER, OR A
                THIRD PARTY CREATES, UPLOADS, POSTS, SENDS, RECEIVES, OR STORES ON OR THROUGH OUR SERVICES. YOU
                UNDERSTAND AND AGREE THAT YOU MAY BE EXPOSED TO CONTENT THAT MIGHT BE OFFENSIVE, ILLEGAL, MISLEADING, OR
                OTHERWISE INAPPROPRIATE, NONE OF WHICH oLIVE WILL BE RESPONSIBLE FOR.
            </div>
            <h3>16. Limitation of Liability</h3>
            <div>
                TO THE MAXIMUM EXTENT PERMITTED BY LAW, oLIVE AND OUR MANAGING MEMBERS, SHAREHOLDERS, EMPLOYEES,
                AFFILIATES, LICENSORS, AND SUPPLIERS WILL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL,
                CONSEQUENTIAL, PUNITIVE, OR MULTIPLE DAMAGES, OR ANY LOSS OF PROFITS OR REVENUES, WHETHER INCURRED
                DIRECTLY OR INDIRECTLY, OR ANY LOSS OF DATA, USE, GOODWILL, OR OTHER INTANGIBLE LOSSES, RESULTING FROM:
                (A) YOUR ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE THE SERVICES; (B) THE CONDUCT OR CONTENT OF
                OTHER USERS OR THIRD PARTIES ON OR THROUGH THE SERVICES; OR (C) UNAUTHORIZED ACCESS, USE, OR ALTERATION
                OF YOUR CONTENT, EVEN IF oLIVE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT WILL
                oLIVE'S AGGREGATE LIABILITY FOR ALL CLAIMS RELATING TO THE SERVICES EXCEED THE GREATER OF $100 USD OR
                THE AMOUNT YOU PAID oLIVE, IF ANY, IN THE LAST 12 MONTHS.
            </div>
            SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES, SO SOME OR ALL OF THE
            EXCLUSIONS AND LIMITATIONS IN THIS SECTION MAY NOT APPLY TO YOU.
            <h3>17. Arbitration, Class Waiver, and Jury Waiver</h3>
            <div>
                PLEASE READ THE FOLLOWING PARAGRAPHS CAREFULLY BECAUSE THEY REQUIRE YOU TO ARBITRATE DISPUTES WITH
                oLIVE, AND LIMIT THE MANNER IN WHICH YOU CAN SEEK RELIEF FROM US.
            </div>
            <ul>
                <li>
                    Applicability of Arbitration Agreement.All claims and disputes arising out of or relating to these
                    Terms or the use of the Services that cannot be resolved in small claims court will be resolved by
                    binding arbitration on an individual basis, except that you and Olive are not required to arbitrate
                    any dispute in which either party seeks equitable relief for the alleged unlawful use of copyrights,
                    trademarks, trade names, logos, trade secrets, or patents.
                </li>
            </ul>
            <li>
                Arbitration Rules. United States Arbitration Law governs the interpretation and enforcementof this
                dispute-resolution provision. Arbitration will be initiated through the United States International
                Economic and Trade Arbitration (“USIETAC”). If the USIETAC is not availableto arbitrate, the parties
                will select an alternative arbitral forum. The rules of the arbitral forum will govern all aspects of
                this arbitration, except to the extent those rules conflict with these Terms.
            </li>
            <li>
                Additional Rules for Non-appearance Arbitration.If non-appearance arbitration is elected, the
                arbitration will be conducted by telephone, online, written submissions, or any combination of the
                three; the specific manner will be chosen by the party initiating the arbitration. The arbitration will
                not involve any personal appearance by the parties or witnesses unless the parties mutually agree
                otherwise.
            </li>
            <li>
                Authority of the Arbitrator. The arbitrator will decide the jurisdiction of the arbitrator and the
                rights and liabilities, if any, of you and Olive. The dispute will not be consolidated with any other
                matters or joined with any other cases or parties. The arbitrator will have the authority to grant
                motions dispositive of all or part of any claim or dispute. The arbitrator will have the authority to
                award monetary damages and to grant any non-monetary remedy or relief available to an individual under
                applicable law, the arbitral forum's rules, and the Terms.The arbitrator will issue a written award and
                statement of decision describing the essential findings and conclusions on which the award is based,
                including the calculation of any damages awarded. The arbitrator has the same authority to award relief
                on an individual basis that a judge in a court of law would have. The award of the arbitrator is final
                and binding upon you and Olive.
            </li>
            <li>
                Waiver of Class or Consolidated Actions. ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION
                AGREEMENT MUST BE ARBITRATED OR LITIGATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS BASIS. CLAIMS OF
                MORE THAN ONE CUSTOMER OR USER CANNOT BE ARBITRATED OR LITIGATEDJOINTLY OR CONSOLIDATED WITH THOSE OF
                ANY OTHER CUSTOMER OR USER.If, however, this waiver of class or consolidated actions is deemed invalid
                or unenforceable, neither you nor we are entitled to arbitration.
            </li>
            <li>
                Confidentiality.No part of the procedures will be open to the public or the media. All evidence
                discovered or submitted at the hearing is confidential and may not be disclosed, except by written
                agreement of the parties, pursuant to court order, or unless required by law. Notwithstanding the
                foregoing, no party will be prevented from submitting to a court of law any information needed to
                enforce this arbitration agreement, to enforce an arbitration award, or to seek injunctive or equitable
                relief.
            </li>
            <li>
                Right to Waive.Any rights and limitations set forth in this arbitration agreement may be waived by the
                party against whom the claim is asserted. Such waiver will not waive or affect any other portion of this
                arbitration agreement.
            </li>
            <li>
                Opt-out.You may opt out of this arbitration agreement. If you do so, neither you nor Olive can force the
                other to arbitrate. To opt out, you must notify Olive in writing no later than 30 days after first
                becoming subject to this arbitration agreement. Your notice must include your name and address, your
                Olive username and the email address you used to set up your Olive account (if you have one), and an
                unequivocal statement that you want to opt-out of this arbitration agreement.
            </li>
            <li>
                Small Claims Court.Notwithstanding the foregoing, either you or Olive may bring an individual action in
                small claims court. Arbitration Agreement Survival.This arbitration agreement will survive the
                termination of your relationship with Olive.
            </li>
            <h3>18. Choice of Law</h3>
            <div>
                Except to the extent they are preempted by the laws of the United States, other than its
                conflict-of-laws principles, govern these Terms and any disputes arising out of or relating to these
                Terms or their subject matter, including tort claims.
            </div>
            <h3>19. Severability</h3>
            <div>
                If any provision of these Terms is found unenforceable, then that provision will be severed from these
                Terms and not affect the validity and enforceability of any remaining provisions.
            </div>
            <h3>20. Additional Terms for Specific Services</h3>
            <div>
                Given the breadth of our Services, we sometimes need to craft additional terms and conditionsfor
                specific Services. Those additional terms and conditions, which will be available with the relevant
                Services, then become part of your agreement with us if you use those Services.
            </div>
            <h3>21. Final Terms</h3>
            <ul>
                <li>
                    These Terms make up the entire agreement between you and Olive, and supersede any prior agreements.
                </li>
                <li>These Terms do no create or confer any third-party beneficiary rights.</li>
                <li>If we do not enforce a provision in these Terms, it will not be considered a waiver.</li>
                <li>We reserve all rights not expressly granted to you.</li>
                <li>You may not transfer any of your rights or obligations under these Terms without our consent.</li>
                <li>
                    These Terms were written in English and to the extent the translated version of these Terms conflict
                    with the English version, the English version will control.
                </li>
            </ul>
            <h3>Contact Us</h3>
            <div>
                Olive welcomes comments, questions, concerns, or suggestions. Please send feedback to us by
                visitingoliveapp.net or use email: <a href="mailto:info@oliveapp.net">info@oliveapp.net</a>.
            </div>
        </div>
    )
}
