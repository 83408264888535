import OneLinkUrlGenerator from './AppsFlyerLinkGenerator'
import getFBCookieString from './getFBCookie'

(function(){
  window.AF = Object.assign((window.AF || {}),{OneLinkUrlGenerator: OneLinkUrlGenerator});
})();

const linkGenerator = new window.AF.OneLinkUrlGenerator({
  oneLinkURL: "https://olivevideochat.onelink.me/F0qB", 
  pidKeysList: ['af_pid', 'pid', 'utm_source'], 
  campaignKeysList: ['af_c', 'c', "campaign.name", 'utm_campaign'], 
  gclIdParam: "af_sub2", 
  campaignStaticValue: 'default0fb', 
  pidStaticValue: 'default0fb', 
  fbp: 'af_sub3'
}); 
linkGenerator.setChannel("af_channel", "default0fb"); 
linkGenerator.setAdset("af_adset", "default0fb"); 
linkGenerator.setAd("af_ad", "default0fb"); 
linkGenerator.setCustomParameter("fbclid", "af_sub1", "default0fb"); 
linkGenerator.setCustomParameter("af_c_id", "af_c_id","default0fb");
linkGenerator.setCustomParameter("af_adset_id", "af_adset_id", "default0fb"); 
linkGenerator.setCustomParameter("af_ad_id", "af_ad_id","default0fb"); 
linkGenerator.setCustomParameter("keyword", "af_keywords", "default0fb");
let urlString = linkGenerator.generateUrl();

if (getFBCookieString(5, true)) {
  urlString += `&${getFBCookieString(5, false)}`  
  urlString += `&${getFBCookieString(5, true)}`
} else {
  const afSub1Start = urlString.indexOf('af_sub1')
  const newString = `${urlString.substr(afSub1Start + 7)}`
  const afSub1End = newString.indexOf('&')
  urlString += `&fbc${newString.substr(0, afSub1End)}`
}

const exportedObj = {
  urlString
}

export default exportedObj