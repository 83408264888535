import React from 'react'
import { Link } from 'react-router-dom'
import { Logo, LogoText } from '../../components/HeaderComponents'
import './Footer.scss'

export default function Footer({ content }) {
    const { privacy, billing, terms, rules, rights } = content
    return (
        <div className="Footer">
            <div className="Footer__logo">
                <Link to={'/'} className="link__logo">
                    <Logo />
                    <LogoText />
                </Link>
            </div>
            <div className="Footer__privacy">
                <span>{rules}</span>
                <div className="Footer__privacy__links">
                    <Link to={'/policy'} target="_blank" className="terms">
                        <span>{privacy}</span>
                    </Link>
                    <Link to={'/terms'} target="_blank" className="terms">
                        <span>{billing}</span>
                    </Link>
                    <Link to={'/service'} target="_blank" className="terms">
                        <span>{terms}</span>
                    </Link>
                </div>
            </div>
            <div className="Footer__rights">
                <span>
                    © 2021{' '}
                    <span className="user-select">
                        <img src={'/images/company.png'} alt="company" />.
                    </span>
                </span>
                <span>{rights}</span>
            </div>
        </div>
    )
}
