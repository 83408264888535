export const getDomainData = async () => {
    const response = await fetch('https://www.cloudflare.com/cdn-cgi/trace', {
        method: 'GET'
    })
    let data = await response.text()
    data = data.trim().split('\n').reduce(function(obj, pair) {
        pair = pair.split('=');
        obj[pair[0]] = pair[1]
        return obj
    }, {})
    return data
}