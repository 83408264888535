import React, { Fragment } from 'react'
import { imagesStack } from '../../../data'
import './MainBannerMap.scss'

const MainBannerMap = () => {
    return (
        <Fragment>
            <div className="image-stack">
                <img className="image-stack__map-image" src={'/images/map_banner1.png'} alt={'map'}></img>
                {imagesStack.map(({ icon, flag, id, text, vector }, index) => {
                    return (
                        <div className="image-stack__profile-item" key={`${id}_${index + 1}`}>
                            <img className="image-stack__profile-item__women-icon" src={icon} alt={`${id}_icon`} />
                            <img className="image-stack__profile-item__flag-icon" src={flag} alt={`${id}_flag`} />
                            <div className="image-stack__profile-item__text">{text}</div>
                            <img className="image-stack__profile-item__vector" src={vector} alt={`${id}_vector`} />
                        </div>
                    )
                })}
            </div>
        </Fragment>
    )
}

export default MainBannerMap
