import React, { useEffect } from 'react'
import { MainBannerMap, MainBannerText, MainBenefitsStack } from '../../components/MainComponents'
import { textStack } from '../../data'
import Button from '../../components/UI/Button'
import './Main.scss'

const Main = ({ handleClick, changeColor, content }) => {
    useEffect(() => {
        changeColor()
        window.scroll(0, 0)
    }, [])
    return (
        <main className="Main">
            <section className="Main__banner">
                <div className="Main__banner__text">
                    <MainBannerText 
                        handleClick={handleClick} 
                        content={content}
                    />
                </div>
                <div className="Main__banner__image-stack">
                    <MainBannerMap />
                </div>
            </section>
            <section className="Main__benefits">
                <div className="Main__benefits__btn">
                    <Button 
                        text={content.btn_main} 
                        classes={['btn', 'btn-yellow']} 
                        handleClick={handleClick} 
                    />
                </div>
                <div className="Main__benefits__line">
                    <img src={'/images/gradient_line.png'} alt={'benefits_line'} />
                </div>
                <div className="Main__benefits__stack">
                    <MainBenefitsStack dataStack={textStack} content={content} />
                </div>
            </section>
        </main>
    )
}

export default Main
