import React, { useEffect } from 'react'
import gtmParts from 'react-google-tag-manager'

const GoogleTagManager = ({
    gtmId,
    scriptId,
    dataLayerName,
    additionalEvents,
    previewVariables,
}) => {
    const gtm = gtmParts({
        id: gtmId,
        dataLayerName: dataLayerName || 'dataLayer',
        additionalEvents: additionalEvents || {},
        previewVariables: previewVariables || false,
    })

    useEffect(() => {
        const dataLayer = dataLayerName || 'dataLayer'
        const script = scriptId || 'react-google-tag-manager-gtm'

        if (!window[dataLayer]) {
            const gtmScriptNode = document.getElementById(script)

            eval(gtmScriptNode.textContent)
        }
    })

    return (
        <div>
            <div>{gtm.noScriptAsReact()}</div>
            <div id={scriptId || 'react-google-tag-manager-gtm'}>{gtm.scriptAsReact()}</div>
        </div>
    )
}

export default GoogleTagManager
