import React, { Fragment } from 'react'
import './MainBenefitsStack.scss'

const MainBenefitsStack = ({ dataStack, content }) => {
    const { 
        benefit1_title, 
        benefit1_description, 
        benefit2_title, 
        benefit2_description, 
        benefit3_title, 
        benefit3_description 
    } = content.benefits
    return (
        <Fragment>
            {dataStack.map(({ icon, circle, description }, index) => {
                return (
                    <div className="benefits__stack-item" key={`${description}__${index}`}>
                        <div className="benefits__stack-item__icon">
                            <img src={circle} alt={`circle_${description}`} />
                            <img src={icon} alt={description} />
                        </div>
                        <div className="benefits__stack-item__text">
                            <h1>{!index ? benefit1_title: index === 1 ? benefit2_title : index === 2 ? benefit3_title: '' }</h1>
                            <span>{!index ? benefit1_description: index === 1 ? benefit2_description : index === 2 ? benefit3_description: ''}</span>
                        </div>
                    </div>
                )
            })}
        </Fragment>
    )
}

export default MainBenefitsStack
