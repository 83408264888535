import React from 'react'
import { Link } from 'react-router-dom'
import { Logo, LogoText } from '../../components/HeaderComponents'
import Button from '../../components/UI/Button'
import './Header.scss'

const Header = ({ handleClick, color, content }) => {
    const { btn_header, header_text } = content
    return (
        <header className={color ? 'Header background-color' : 'Header'}>
            <div className="Header__container">
                <div className="Header__container_logo">
                    <Link to="/" className="link__logo">
                        <Logo />
                        <LogoText header_text={header_text}/>
                    </Link>
                </div>
                <Button text={btn_header} classes={['btn', 'btn-logo']} handleClick={handleClick} />
            </div>
        </header>
    )
}

export default Header
