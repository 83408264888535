import React from 'react'

export default function LogoText({ header_text }) {
    return (
        <div className="logo-text-group">
            <span className="logo-text">Olive</span>
            <span className="logo-text">{header_text}</span>
        </div>
    )
}
