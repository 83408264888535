import React from 'react'
const logo = '/images/logo_olive.png'

const Logo = () => (
    <div className="logo">
        <img src={logo} alt="logo" />
    </div>
)

export default Logo
